import React from 'react';
import Header from './components/Header';
import FeaturesSection from './components/FeaturesSection';
import Footer from './components/Footer';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main>
        {/* Main content goes here, possibly including a hero section */}
      </main>
      <FeaturesSection />
      <Footer />
    </div>
  );
}

export default App;

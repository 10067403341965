import React from 'react';
import styles from './Header.module.css'; // Make sure to create this CSS file for your styles

const Header: React.FC = () => {
  return (
    <div>
      <div className={styles.eclipse_5}></div>
    <header className={styles.header}>
      <h1 className={styles.header_title_first}>Votio</h1>
      <h1 className={styles.header_title}>Who’s Most Likely To...</h1>
      <p className={styles.header_subtitle}>
        Vote on which one of your friends relate most to the daily statement. Make friends
        and add them to your groups.
      </p>
      <div className={styles.header_buttons}>
        <button className={styles.download_button} onClick={() => console.log('Download App')}>
          Download App
        </button>
        <button className={styles.join_discord_button} onClick={() => console.log('Join Discord')}>
          Join Discord
        </button>
      </div>
    </header>
    </div>
  );
};

export default Header;


import React from "react";
import styles from "./FeaturesSection.module.css";

interface FeatureCardProps {
  icon: any;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
}) => {
  return (
    <div className="feature-card">
      {icon}
      <h3>{title}</h3>
      <p>{description}</p>
      <a href="#read-more" className={styles.read_more_link}>
        Read More
      </a>
    </div>
  );
};
const FeaturesSection: React.FC = () => {
  const communityIcon = (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="22.0003" cy="16.5001" r="7.33333" fill="white" />
      <circle cx="31.167" cy="16.5" r="5.5" fill="white" />
      <circle cx="12.833" cy="16.5" r="5.5" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.2088 32.9999H37.395C37.9866 32.9999 38.441 32.4911 38.3272 31.9106C37.8514 29.4814 36.2035 23.8333 31.1667 23.8333C29.5422 23.8333 28.2702 24.4208 27.2764 25.2983C30.0406 27.0917 31.4698 30.232 32.2088 32.9999Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7237 25.2983C15.7299 24.4208 14.4579 23.8333 12.8334 23.8333C7.79658 23.8333 6.14864 29.4814 5.67281 31.9106C5.55908 32.4911 6.01343 32.9999 6.60505 32.9999H11.7913C12.5303 30.232 13.9595 27.0917 16.7237 25.2983Z"
        fill="white"
      />
      <path
        d="M21.9997 25.6667C29.4643 25.6667 30.8503 32.9611 31.1077 35.6699C31.1599 36.2197 30.7186 36.6667 30.1663 36.6667H13.833C13.2807 36.6667 12.8395 36.2197 12.8917 35.6699C13.149 32.9611 14.535 25.6667 21.9997 25.6667Z"
        fill="white"
      />
    </svg>
  );
  const engagementIcon = (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="22"
        cy="22"
        r="17.5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M29.3337 14.6667L14.667 20.1667"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14.6663 14.6667L29.333 20.1667"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M14.667 26.6667C14.667 26.1953 14.667 25.9596 14.8134 25.8132C14.9599 25.6667 15.1956 25.6667 15.667 25.6667H28.3337C28.8051 25.6667 29.0408 25.6667 29.1872 25.8132C29.3337 25.9596 29.3337 26.1953 29.3337 26.6667V27.5001C29.3337 30.6275 29.3337 32.1912 28.5071 33.2685C28.2943 33.5458 28.046 33.794 27.7687 34.0068C26.6915 34.8334 25.1278 34.8334 22.0003 34.8334C18.8729 34.8334 17.3092 34.8334 16.2319 34.0068C15.9546 33.794 15.7064 33.5458 15.4936 33.2685C14.667 32.1912 14.667 30.6275 14.667 27.5001V26.6667Z"
        fill="white"
      />
    </svg>
  );

  const personalizationIcon = (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.4871 28.5327C34.4072 26.9393 35.9634 25.2259 37.0111 23.9538C37.7228 23.0898 38.0786 22.6578 38.0786 22C38.0786 21.3422 37.7228 20.9102 37.0111 20.0462C34.4076 16.8853 28.6652 11 21.9997 11C20.0004 11 18.084 11.5295 16.3106 12.3562L20.627 16.6727C21.0658 16.5599 21.5258 16.5 21.9997 16.5C25.0373 16.5 27.4997 18.9624 27.4997 22C27.4997 22.474 27.4398 22.9339 27.3271 23.3727L32.4871 28.5327ZM17.9693 18.2576C17.0574 19.2392 16.4997 20.5545 16.4997 22C16.4997 25.0376 18.9622 27.5 21.9997 27.5C23.4453 27.5 24.7605 26.9424 25.7422 26.0304L30.0452 30.3334C27.665 31.8695 24.921 33 21.9997 33C15.3342 33 9.59184 27.1147 6.98834 23.9538C6.27671 23.0898 5.9209 22.6578 5.9209 22C5.9209 21.3422 6.27671 20.9102 6.98834 20.0462C8.39488 18.3385 10.7176 15.8357 13.607 13.8953L17.9693 18.2576Z"
        fill="white"
      />
      <path
        d="M9.16699 3.6665L38.5003 32.9998"
        stroke="white"
        stroke-width="2"
      />
    </svg>
  );

  return (
    <section className={styles.features_section}>
      <h2>Our Features</h2>
      <div className={styles.features_grid}>
        <FeatureCard
          icon={communityIcon}
          title="Community & Connection"
          description="Create and customize your profile, engage in-app, with robust privacy settings."
        />
        <FeatureCard
          icon={engagementIcon}
          title="Engagement & Fun"
          description="Vote on
          statements, enjoy animations, and compete in a leaderboard system."
        />
        <FeatureCard
          icon={personalizationIcon}
          title="Personalization & Accessibility"
          description="Customize themes, set notifications, and enjoy multi-language support."
        />
      </div>
    </section>
  );
};

export default FeaturesSection;
